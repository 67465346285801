import componentFactory from '../../modules/component-factory';
import { endpoints } from '../../modules/services/api-service';

export { createList, configureBackLink };

function createList(response, handleSelectOption, enterManuallyCallback) {
  let items = response.options;
  let listItems = [];

  for (let i = 0; i < items.length; i++) {
    let listItem = createListItem(items[i], i);

    listItem.addEventListener('mousedown', event => handleSelectOption(event, listItem));

    listItems.push(listItem);
  }

  let enterManually = createEnterManually(response, enterManuallyCallback);

  listItems.push(enterManually);

  let list = createParentList(listItems);

  let parentList = [list];

  let parentDivList = createParentWrapper(parentList);

  return parentDivList;
}

function configureBackLink(parentDivList) {
  let firstListItem = parentDivList.querySelector('ul > li');

  firstListItem.classList.add('autoaddress-back');
  let ariaLabel = firstListItem.getAttribute('aria-label');
  const santitisedAriaLabel = ariaLabel.replace('< ', '');
  firstListItem.setAttribute(
    'aria-label',
    `Options for ${santitisedAriaLabel}. Click here to go back to your previous results`
  );
}

function createParentList(listItems) {
  return componentFactory(
    'ul',
    {
      id: 'autoaddress-list',
      role: 'listbox',
    },
    ...listItems
  );
}

function createParentWrapper(parentList) {
  return componentFactory(
    'div',
    {
      id: 'autoaddress-list-container',
    },
    ...parentList
  );
}

function createListItem(item, index) {
  let itemClass = 'autoaddress-dropdown-item';

  let itemChildElements = [];
  let addressLineElements = getAddressLineElements(item);
  let textDiv = componentFactory(
    'div',
    {
      class: 'autoaddress-dropdown-item-text',
    },
    ...addressLineElements
  );
  let ariaLabel = item.value;

  itemChildElements.push(textDiv);

  if (item.suffix) {
    itemClass += ' autoaddress-dropdown-item-has-options';

    let italicText = componentFactory('em', {
      class: 'italic-text',
      innerHTML: item.suffix,
    });

    let addressCountDiv = componentFactory(
      'div',
      {
        class: 'autoaddress-item-list',
      },
      italicText
    );

    let arrowImg = componentFactory('p', {
      class: 'arrow italic-text',
      innerHTML: '<b>&gt;</b>',
    });

    itemChildElements.push(addressCountDiv);
    itemChildElements.push(arrowImg);

    if (item.value.includes('A - Z')) {
      ariaLabel = `Business names. Click to view ${item.suffix}`;
    } else {
      // Suffix looks like '48 addresses'. This adds that context to the aria label so user knows more addresses lie within
      ariaLabel = `${ariaLabel}. Click to view ${item.suffix}`;
    }
  }

  let listItem = componentFactory(
    'li',
    {
      id: 'autoaddress-item-' + index,
      class: itemClass,
      datalink: item.link.href,
      'aria-selected': false,
      role: 'option',
      'aria-label': ariaLabel,
      tabindex: -1,
    },
    ...itemChildElements
  );

  return listItem;
}

function getAddressLineElements(listItem) {
  let lineElements = [];

  if (listItem.format.highlights.length > 0 && listItem.format.highlights.length % 2 == 0) {
    if (listItem.format.highlights[0] > 0) {
      let standardBefore = componentFactory('span', {
        class: 'addressline',
        innerHTML: listItem.value.substring(0, listItem.format.highlights[0]),
      });

      lineElements.push(standardBefore);
    }

    //TODO: loop here would look at highlight ints in pairs, add to array in alternating bold/standard and
    let bold = componentFactory('strong', {
      class: 'bold-addressline',
      innerHTML: listItem.value.substring(
        listItem.format.highlights[0],
        listItem.format.highlights[1]
      ),
    });

    lineElements.push(bold);
  }

  let endIndex = listItem.format.highlights[1] ?? 0;

  let standardAfter = componentFactory('span', {
    class: 'addressline',
    innerHTML: listItem.value.substring(endIndex),
  });

  //check if back header and change class to use bold-addressline.
  if (listItem.format.highlights[1] == 1) {
    standardAfter = componentFactory('span', {
      class: 'bold-addressline',
      innerHTML: listItem.value.substring(endIndex),
    });
  }

  lineElements.push(standardAfter);

  if (listItem.format.lineBreaks && listItem.format.lineBreaks.length > 0) {
    let lineBreakStartPosition;
    let hasSplit = false;
    lineBreakStartPosition = listItem.format.lineBreaks[0];

    if (lineBreakStartPosition > 0) {
      lineElements.forEach(element => {
        if (lineBreakStartPosition < element.innerText.length && !hasSplit) {
          let beforeBreak = element.innerText.slice(0, lineBreakStartPosition);
          element.innerText = '';
          if (lineBreakStartPosition > 0) {
            let beforeBreakChild = componentFactory('span', {
              class: 'autoaddress-upper-line',
              innerHTML: beforeBreak,
            });
            element.appendChild(beforeBreakChild);
          }
          let lineBreak = componentFactory('br');
          element.appendChild(lineBreak);
          hasSplit = true;
        }
      });
    }
  }
  return lineElements;
}

function createEnterManually(response, enterManuallyCallback) {
  let manualEntryLink = '';
  let manualEntryTitle = '';

  response.links.forEach(element => {
    if (element.rel == endpoints.ADDRESS_FORM_LAYOUT) {
      manualEntryLink = element.href;
      manualEntryTitle = element.title;
    }
  });

  let enterManually = componentFactory('li', {
    id: 'autoaddress-manual-enter',
    class: 'autoaddress-dropdown-item',
    innerHTML: manualEntryTitle,
    datalink: manualEntryLink,
    role: 'option',
    'aria-label': manualEntryTitle.replace('>', ''),
    tabindex: -1,
  });

  enterManually.addEventListener('click', () => enterManuallyCallback(enterManually));

  return enterManually;
}
