import componentFactory from '../../modules/component-factory';

export function createContainer(
  input,
  callbacks,
  options,
  magnifyingGlass,
  search,
  clearList,
  handleKeydown
) {
  let searchIcon = componentFactory('img', {
    class: 'magnifying-glass',
    src: magnifyingGlass,
  });

  let searchLoading = componentFactory('div', {
    class: 'spinner',
  });

  let button = componentFactory(
    'button',
    {
      type: 'button',
      id: 'autoaddress-button',
      dataloading: 'false',
      'aria-label': 'Search button',
    },
    searchIcon,
    searchLoading
  );

  button.addEventListener('click', function () {
    if (callbacks.onClickSearchButton(input.value) !== false) {
      if (input.value.length > 3) {
        search(input.value);
      }
      if (input.value.length < 1) {
        clearList();
      }
    }
  });

  let jsControlDiv = componentFactory(
    'div',
    {
      class: 'autoaddress-input-control',
    },
    input,
    button
  );

  jsControlDiv.addEventListener('keydown', handleKeydown);

  let container = componentFactory(
    'div',
    {
      id: 'autoaddress-container',
      class: options.theme,
    },
    jsControlDiv
  );

  return container;
}

export function createInput(settings, callbacks, autocomplete, clearList) {
  // Create Autocomplete Input
  let input = componentFactory('input', {
    type: 'text',
    id: 'autoaddress-input',
    placeholder: settings.placeholder,
    autocomplete: 'off',
    autocorrect: 'off',
    autocapitalize: 'none',
    spellcheck: 'false',
    // Ensure 1Password does not try to fill the input
    'data1p-ignore': '',
    role: 'combobox',
    'aria-expanded': false,
    'aria-controls': 'autoaddress-list',
    'aria-autocomplete': 'list',
  });

  input.addEventListener('input', function () {
    if (callbacks.onPreAutocomplete(this.value) !== false) {
      if (this.value.length > 3) {
        autocomplete(this.value);
      }
    }
    if (this.value.length < 1) {
      clearList();
    }
  });

  input.addEventListener('click', function () {
    callbacks.onClickInputField(input.value);
  });

  return input;
}
