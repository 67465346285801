import filterInput from './html/filter-input.html';
import { linkTypes } from '../../../modules/services/api-service';
import Utils from '../../../modules/helpers/utils';

export { attachFilterInput, responseHasFilter, getFilterElement, getFilterLink, setFocus };

function attachFilterInput(parentDivList, filterInput) {
  let firstListItem = parentDivList.querySelector('ul > li');
  let input = filterInput.querySelector('input');
  input.focus();
  firstListItem.appendChild(filterInput);
}

function getFilterElement(link, filterCallback) {
  let filterInputElement = Utils.getElementFromHtml(filterInput);
  filterInputElement.addEventListener('mousedown', event => {
    event.stopPropagation();
    return false;
  });
  filterInputElement.addEventListener('input', e => {
    const updatedLink = setFilterParam(link, e.target.value);
    filterCallback(updatedLink);
  });
  let input = filterInputElement.querySelector('input');
  input.focus();
  return filterInputElement;
}

function setFilterParam(link, filterParam) {
  const url = new URL(link);
  url.searchParams.set('filter', filterParam);
  return url.href;
}

function setFocus(filterInputElement) {
  let input = filterInputElement.querySelector('input');
  input.focus();
}

function responseHasFilter(response) {
  return response.links.some(x => x.rel == linkTypes.FILTER);
}

function getFilterLink(response) {
  return response.links.find(x => x.rel === linkTypes.FILTER).href;
}
